import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.works.project.member');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Project } from "src/libs/portal/works/project";

@Component({
    selector: 'wiz-portal-works-project-member',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet-v4/branch/main/build/src/app/portal.works.project.member/view.scss */
td .btn,
th .btn {
  min-width: 24px;
}`],
})
export class PortalWorksProjectMemberComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,@Inject( Project    )         public project: Project    ) { }

    public readOnly: boolean = true;
    public newuser: any = { role: 'user' };

    public async ngOnInit() {
        await this.service.init();
        this.readOnly = !this.project.accessLevel(['admin', 'manager']);
        await this.project.member.load();
        await this.service.render();
    }

    public async alert(message: string, status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async roleChanged(user: any) {
        await this.project.member.update(user.user, user.role);
        await this.project.member.load();
        await this.service.render();
    }

    public async remove(user: any) {
        await this.project.member.remove(user.user);
        await this.project.member.load();
        await this.service.render();
    }

    public async create() {
        let { email, role } = this.newuser;
        email = email.replace(" ", "");
        const { code, data } = await this.project.member.create(email, role);

        if (code != 200) {
            await this.alert(data);
            return;
        }

        this.newuser = { role: 'user' };
        await this.project.member.load();
        await this.service.render();
    }

}

export default PortalWorksProjectMemberComponent;