import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonetadm.availability');
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import Handsontable from "handsontable";
import toastr from "toastr";

@Component({
    selector: 'wiz-portal-kreonetadm-availability',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet-v4/branch/main/build/src/app/portal.kreonetadm.availability/view.scss */
.handsontable-container {
  width: 100%;
  height: 100%;
  flex: auto;
  padding: 24px;
  max-width: 1180px;
  margin: 0 auto;
}

.container {
  height: max-content;
}`],
})
export class PortalKreonetadmAvailabilityComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        this.year = new Date().getFullYear();
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        await this.load();
        await this.service.render();
    }

    @ViewChild('handsontable')
    public table: ElementRef;
    public handsontable: any = null;
    public year: number = 0;
    public listloaded: boolean = false;

    public async load() {
        if (this.year < 1000 || this.year > 10000) return;
        this.listloaded = false;
        await this.service.render();

        let { data } = await wiz.call("data", { year: this.year });

        this.listloaded = true;
        await this.service.render();

        let ctx = this.table.nativeElement;
        this.handsontable = new Handsontable(ctx, {
            data: data,
            height: '100%',
            colWidths: [120, 120, 80,
                60, 60, 60, 60,
                60, 60, 60, 60,
                60, 60, 60, 60],
            colHeaders: [
                "FROM",
                "TO",
                "국내/국제",
                "1월",
                "2월",
                "3월",
                "4월",
                "5월",
                "6월",
                "7월",
                "8월",
                "9월",
                "10월",
                "11월",
                "12월"
            ],
            columns: [
                { data: '_from', type: "text" },
                { data: '_to', type: "text" },
                { data: 'category', type: "dropdown", source: ['국내', '국제'] },
                { data: 'm1', type: "numeric" },
                { data: 'm2', type: "numeric" },
                { data: 'm3', type: "numeric" },
                { data: 'm4', type: "numeric" },
                { data: 'm5', type: "numeric" },
                { data: 'm6', type: "numeric" },
                { data: 'm7', type: "numeric" },
                { data: 'm8', type: "numeric" },
                { data: 'm9', type: "numeric" },
                { data: 'm10', type: "numeric" },
                { data: 'm11', type: "numeric" },
                { data: 'm12', type: "numeric" }
            ],
            dropdownMenu: true,
            hiddenColumns: {
                indicators: true
            },
            contextMenu: true,
            multiColumnSorting: true,
            filters: true,
            rowHeaders: true,
            manualRowMove: true,
            minSpareRows: 1,
            licenseKey: "non-commercial-and-evaluation"
        });

        console.log(this.handsontable);
    }

    public async update() {
        let data = this.handsontable.getData();
        let keys = '_from,_to,category,m1,m2,m3,m4,m5,m6,m7,m8,m9,m10,m11,m12'.split(",");
        for (let i = 0; i < data.length; i++) {
            let tmp = {};
            for (let j = 0; j < data[i].length; j++)
                tmp[keys[j]] = data[i][j];
            data[i] = tmp;
        }

        await wiz.call("update", { data: JSON.stringify(data), year: this.year });
        toastr.success("저장되었습니다");
    }
}

export default PortalKreonetadmAvailabilityComponent;