import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dizest.editor.notsupported');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-dizest-editor-notsupported',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet-v4/branch/main/build/src/app/portal.dizest.editor.notsupported/view.scss */
.view-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.view-container h2 {
  margin: 0;
}`],
})
export class PortalDizestEditorNotsupportedComponent implements OnInit {
    @Input() tab: any = {};

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.render();
    }
}

export default PortalDizestEditorNotsupportedComponent;