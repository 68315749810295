import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dizest.sidebar.codeflow');
import { OnInit, OnDestroy } from '@angular/core';
import { Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import $ from 'jquery';

@Component({
    selector: 'wiz-portal-dizest-sidebar-codeflow',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet-v4/branch/main/build/src/app/portal.dizest.sidebar.codeflow/view.scss */
.workspace-tab {
  height: 100%;
  width: 50vw;
  max-width: 720px;
  min-width: 480px;
  display: flex;
  flex-direction: column;
  background: #fff;
  background: var(--wc-background);
  border-left: 1px solid var(--wc-border);
}
.workspace-tab .workspace-tab-header {
  display: flex;
  background: var(--wc-blue);
  padding: 4px 12px;
  height: 40px;
  align-items: center;
}
.workspace-tab .workspace-tab-header .workspace-tab-header-title {
  color: white;
  font-family: "wiz-eb";
  font-size: 16px;
}
.workspace-tab .workspace-tab-header .close-btn {
  font-size: 16px;
  cursor: pointer;
}
.workspace-tab .workspace-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  flex: 1;
}
.workspace-tab .workspace-tab-body {
  padding: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: auto;
  padding-bottom: 40vh;
}
.workspace-tab .workspace-tab-body::-webkit-scrollbar-track {
  background-color: #fff;
}
.workspace-tab .workspace-tab-body::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background-color: #fff;
}
.workspace-tab .workspace-tab-body::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: var(--wc-scroll-thumb);
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}

.card-header {
  padding: 8px 12px;
  color: #fff;
  background: var(--wc-gray-hover);
  border-top: 1px solid var(--wc-border);
}
.card-header .btn-codeflow-close {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.card-header .btn-group {
  height: 24px;
}
.card-header .btn-group .btn {
  width: 76px;
}

.code-editor {
  position: relative;
}

.is_dragging .code-editor-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.codeflow-body {
  opacity: 0.3;
}
.codeflow-body.selected {
  opacity: 1;
}
.codeflow-body.selected .code-editor {
  border: 3px solid var(--wc-blue);
}

.avatar {
  border-radius: 12px;
}

.no-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload {
  --background: #FFFFFF;
  --text-drop: #CAC8CA;
  --text-upload: #2D2C2F;
  --progress-color: #E7E5E7;
  --check-color: var(--text-upload);
  --border-radius: 30px;
  width: 300px;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: var(--background);
  border-radius: var(--border-radius);
  transition: all 0.3s ease-out;
  box-shadow: var(--shadow-x, 0px) var(--shadow-y, 1px) var(--shadow-blur, 3px) rgba(0, 0, 0, 0.1);
  transform: scale(var(--scale, 1));
}
.upload .drop-here {
  position: absolute;
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: var(--border-radius);
  opacity: var(--opacity, 0);
  overflow: hidden;
  cursor: pointer;
  text-indent: -9999px;
  z-index: 1;
}
.upload .text {
  position: absolute;
  font-size: 21px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
}
.upload .text.text-drop {
  color: var(--text-drop);
  opacity: var(--opacity, 1);
  transition: opacity 0.15s ease-out 0.15s;
}
.upload .text.text-upload {
  color: var(--text-upload);
  opacity: var(--opacity, 0);
  transition: opacity 0.15s ease-out;
}
.upload .progress-wrapper {
  position: absolute;
}
.upload .progress-wrapper .progress {
  fill: none;
  stroke: var(--progress-color);
  stroke-width: 3;
  stroke-dasharray: 722;
  stroke-dashoffset: 722;
}
.upload .check-wrapper {
  position: absolute;
  opacity: var(--opacity, 0);
  transform: scale(var(--scale, 0.9)) rotate(var(--rotate, 3deg));
  transition: transform 0.15s ease-in-out, opacity 0.15s ease-in;
}
.upload .check-wrapper .check {
  width: 100px;
  width: 100px;
  fill: none;
  stroke: var(--check-color);
  stroke-width: 7;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 100 0;
  stroke-dashoffset: 100;
}
.upload .shadow {
  opacity: var(--opacity, 0);
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: var(--border-radius);
  filter: blur(25px);
  z-index: -1;
  transition: all 0.5s ease;
}
.upload .shadow::before {
  content: "";
  position: absolute;
  top: -25%;
  left: -25%;
  height: 150%;
  width: 150%;
  background: conic-gradient(#4b82d4, #6a29e9, #4b82d4, #6a29e9, #c58357, #FFC940, #4b82d4);
  animation: shadow-animate 5s linear infinite;
}
.upload.drag {
  --scale: 1.03;
  --shadow-y: 5px;
  --shadow-blur: 20px;
}
.upload.drop .text.text-drop {
  --opacity: 0;
  transition: opacity 0.15s ease-out;
}
.upload.drop .text.text-upload {
  --opacity: 1;
  transition: opacity 0.15s ease-out 0.15s;
}
.upload.drop .shadow {
  --opacity: 1;
}
.upload.drop .progress-wrapper {
  opacity: var(--opacity, 1);
  transform: scale(var(--scale, 1)) rotate(var(--rotate, -90deg));
}
.upload.drop .progress-wrapper .progress {
  animation: progress-animate 3s ease 0.3s forwards;
}
.upload.done {
  --opacity: 0;
}
.upload.done .text.text-upload {
  --opacity: 0;
}
.upload.done .shadow {
  --opacity: 0;
}
.upload.done .progress-wrapper {
  --scale: .95;
  transition: transform 0.3s, opacity 0.3s;
}
.upload.done .check-wrapper {
  --opacity: 1;
  --scale: 1;
  --rotate: 0deg;
  transition: transform 0.5s ease-in-out 0.3s, opacity 0.5s ease-in 0.3s;
}
.upload.done .check-wrapper .check {
  animation: check-animate 0.5s ease-in-out 0.3s forwards;
}

@keyframes shadow-animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes progress-animate {
  0% {
    stroke-dashoffset: 722;
  }
  20% {
    stroke-dashoffset: 500;
  }
  50% {
    stroke-dashoffset: 322;
  }
  55% {
    stroke-dashoffset: 300;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes check-animate {
  0% {
    stroke-dasharray: 0 100;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 100 0;
    stroke-dashoffset: 100;
  }
}
.codeflow-debug-area {
  border-top: 1px solid var(--wc-border);
  padding: 12px;
  background: #fff;
  max-height: 460px;
  overflow: auto;
}
.codeflow-debug-area::-webkit-scrollbar-track {
  background-color: #fff;
}
.codeflow-debug-area::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: #fff;
}
.codeflow-debug-area::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: var(--wc-blue-light-3);
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}

.debug-message {
  color: var(--tblr-code-color);
  font-size: 12px;
  border: none;
  background: #fff;
  padding: 6px;
  overflow: auto;
  cursor: auto;
  white-space: nowrap;
  border-radius: 0;
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  margin: 0;
  line-height: 16px;
  font-family: "main-r";
}
.debug-message.hide {
  display: none;
}`],
})
export class PortalDizestSidebarCodeflowComponent implements OnInit, OnDestroy {
    @Input() sidebar: any = null;

    public workflow: any;

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        this.workflow = this.sidebar.selected.workflow;
    }

    public async ngOnDestroy() {
    }

    public is_dragging: boolean = false;

    public monacopy: any = {
        value: '',
        language: 'python',
        theme: "vs",
        fontSize: 14,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        wordWrap: true,
        roundedSelection: false,
        glyphMargin: false,
        scrollbar: {
            vertical: "hidden",
            handleMouseWheel: false,
        },
        minimap: {
            enabled: false
        }
    }

    public monacomd: any = {
        value: '',
        language: 'markdown',
        theme: "vs",
        fontSize: 14,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        wordWrap: true,
        roundedSelection: false,
        glyphMargin: false,
        scrollbar: {
            vertical: "hidden",
            handleMouseWheel: false,
        },
        minimap: {
            enabled: false
        }
    }

    public async drag(status: boolean, flow: any) {
        this.is_dragging = status;
        await this.service.render();
    }

    public codes() {
        return this.workflow.codeflow.codes;
    }

    public async close() {
        await this.sidebar.close();
    }

    public async closeCode(flow: any) {
        await this.workflow.flow.select();
        await this.workflow.codeflow.close(flow);
    }

    public async init(flow: any, event: any) {
        let { editor } = event;

        let activated = false;

        let monaco_auto_height = (forced: boolean = false) => async () => {
            if (activated) return;
            if (!forced)
                if (flow.ctrl.selected)
                    if (!flow.selected())
                        return;

            activated = true;

            const el = editor.getDomNode();
            if (!el) return;

            let getLines = () => {
                return $(el).find('.view-lines .view-line');
            }

            let lineUtil = async (margin: number = 0) => {
                let lines = getLines();
                if (lines.length == 0) return;
                let last_line = lines[lines.length - 1];
                let top = last_line.style.top.replace("px", "") * 1;
                let height = top + last_line.style.height.replace("px", "") * 1;

                for (let i = 0; i < lines.length; i++) {
                    let _top = lines[i].style.top.replace("px", "") * 1;
                    let _height = _top + lines[i].style.height.replace("px", "") * 1;
                    if (_height > height) height = _height;
                }

                height = height + margin;
                if (height < 105) height = 105;
                el.style.height = height + 'px';
                editor.layout();
            }

            let lineEstimate = async () => {
                let pre_line = -1;
                for (let i = 0; i < 100; i++) {
                    await this.service.render();
                    let real_line = getLines().length;
                    if (real_line == pre_line) {
                        await lineUtil();
                        return;
                    }

                    await lineUtil(630);
                    pre_line = real_line;
                }
            }

            let lines = getLines();
            if (lines.length == 0) {
                el.style.height = '105px';
                editor.layout();
            }

            await lineEstimate();
            activated = false;
        }

        flow.monaco_auto_height = monaco_auto_height;
        monaco_auto_height(true)();
        editor.getModel().onDidChangeContent(monaco_auto_height());
    }

    public async select(flow: any) {
        if (flow && !this.selected(flow)) {
            await this.workflow.flow.select(flow.id());
            if (flow.monaco_auto_height)
                flow.monaco_auto_height();
        }
    }

    public selected(flow: any) {
        return flow.id() == this.workflow.flow.selected;
    }

    public async find(flow: any) {
        if (this.workflow.drawflow)
            await this.workflow.drawflow.find(flow.id());
    }
}

export default PortalDizestSidebarCodeflowComponent;