import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.notice');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import showdown from 'showdown';
import $ from 'jquery';

@Component({
    selector: 'wiz-page-notice',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet-v4/branch/main/build/src/app/page.notice/view.scss */
.wiz-view {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  padding-top: 24px;
}

.wiz-view::-webkit-scrollbar {
  display: none;
}

.season-page {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  max-width: 1280px;
  width: 90vw;
  margin: 0px auto;
  border-top: 1px solid #2b2c2e;
  border-left: 1px solid #2b2c2e;
  border-right: 1px solid #2b2c2e;
}
@media (max-width: 768px) {
  .season-page {
    width: 92vw;
  }
}

.season-page::-webkit-scrollbar {
  display: none;
}

.header {
  background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.5), transparent), url("/assets/background/board.jpg");
  min-height: 320px;
  max-height: 320px;
  padding-bottom: 12px;
}
.header .text-container {
  max-width: 1120px;
}

.content {
  min-height: 100%;
}

.content-container {
  max-width: 1280px;
}

.sticky-top {
  padding: 8px 48px;
  position: sticky;
}
@media (max-width: 768px) {
  .sticky-top {
    display: none;
  }
}

.noitems h2 {
  font-size: 26px;
  color: #72787F;
}
@media (max-width: 768px) {
  .noitems {
    margin: 0 !important;
  }
}

button {
  margin: 10px 0px;
  margin-right: 15px;
  border-radius: 30px;
  height: auto;
  font: normal normal normal 16px Montserrat;
}

.btn-news {
  padding: 12px 30px;
}

.btn-read {
  background-color: #FF5933;
  color: white;
  border-color: white;
  padding: 12px 28px;
  margin-top: 12px !important;
}
@media (max-width: 768px) {
  .btn-read {
    margin-top: 12px !important;
  }
}

.opt {
  border: 1px solid #72787F;
}
.opt:hover {
  color: #FF5933;
  border: 1px solid #FF5933;
  background-color: #FFF2ED;
}
.opt.active {
  background-color: #FF5933;
  color: #FFF;
  border: 1px solid #FF5933;
}

.var {
  align-items: center;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
}

.card {
  margin: auto;
  border: none;
}
.card .card-body {
  padding: 52px 48px;
  border-bottom: 1px solid #E9E8EE;
  border-top: none;
  display: flex;
  cursor: pointer;
}
.card .card-body:hover {
  background: #FFF2ED;
}
@media (max-width: 768px) {
  .card .card-body {
    padding: 24px 0;
    display: block;
  }
}
.card .card-text {
  text-align: left;
}
.card .font-gray {
  color: gray;
}
.card h3 {
  font-size: 24px;
  margin-top: 4px;
}
.card .detail {
  height: 70px;
  font: normal normal normal 16px/25px SUIT;
}

.featured-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 360px;
  height: 180px;
  max-width: 100%;
  border-radius: 24px;
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
  margin-right: 32px;
}
@media (max-width: 768px) {
  .featured-image {
    height: 200px;
    margin: 0;
    margin-bottom: 32px;
  }
}`],
})
export class PageNoticeComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public category: any = '';
    public items: any = [];
    public loaded: boolean = false;

    public search: any = { page: 1 };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/');
        await this.load();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        this.items = [];
        await this.service.render();
        this.search.category = this.category;
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.items = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public async changeCategory(category: string) {
        this.category = category;
        this.service.render();
        await this.load(1);
    }

    public desc(item: any) {
        let text: any = item.content;
        let converter = new showdown.Converter();
        text = converter.makeHtml(text);
        text = $(text).text();
        if (text.length > 140) text = text.substring(0, 138) + '...';
        return text;
    }
}

export default PageNoticeComponent;