import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonet.widget.availability');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ApexCharts from 'apexcharts';

@Component({
    selector: 'wiz-portal-kreonet-widget-availability',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet-v4/branch/main/build/src/app/portal.kreonet.widget.availability/view.scss */
.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 1024px;
}
.wiz-modal .modal-dialog {
  width: 100%;
  height: 100%;
  max-width: 1024px;
}
.wiz-modal .modal-content {
  width: 100%;
  height: 100%;
  max-width: 1024px;
}
.wiz-modal .modal-content .scrollable {
  overflow: auto;
}

.btns-location {
  text-align: center;
}
.btns-location .btn {
  padding-right: 8px;
  padding-left: 8px;
  margin-right: 4px;
  margin-bottom: 4px;
}

.selected-item-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.selected-item-container .selected-header {
  background: var(--tblr-light);
  border-bottom: 1px solid var(--wc-border);
  padding: 4px 8px;
}
.selected-item-container .list-group {
  flex: 1;
}

.scrollable {
  height: 100%;
  overflow: auto;
}`],
})
export class PortalKreonetWidgetAvailabilityComponent implements OnInit {

    @ViewChild('chart1')
    public element1: ElementRef;

    @ViewChild('chart2')
    public element2: ElementRef;

    public showModal: boolean = false;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        this.year = new Date().getFullYear();
        await this.service.init();
        await this.load();

        if (!this.years.includes(this.year)) {
            this.year = this.years[this.years.length - 1];
            await this.load();
        }

    }

    public async openModal() {
        this.showModal = true;
        await this.service.render();
    }

    public async hide() {
        this.showModal = false;
        await this.service.render();
    }

    public data: any = {};
    public year: number = 0;
    public years: any = [];

    public async load() {
        let { data } = await wiz.call("data", { year: this.year });
        let { internal, external, years, internalcd, externalcd, rows } = data;
        this.years = years;
        this.data.internal = internal;
        this.data.external = external;
        this.data.raw = rows;

        let months: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        for (let i = 0; i < months.length; i++) {
            months[i] = months[i] + '월';
        }

        await this.service.render();
        await this.render(this.element1, [
            { name: '국내', data: internalcd }
        ], months, "월별 국내 가용률");

        await this.render(this.element2, [
            { name: '국제', data: externalcd }
        ], months, "월별 국제 가용률");
    }

    public async render(element: any, series: any, category: any, title: any) {
        element.nativeElement.innerHTML = "";

        let options = {
            series: series,
            chart: {
                height: 120,
                type: 'area',
                sparkline: {
                    enabled: true
                },
                toolbar: {
                    show: false
                },
                animation: {
                    enabled: true,
                    dynamicAnimation: {
                        enabled: true
                    }
                }
            },
            grid: {
                padding: {
                    right: 0,
                    left: 0
                }
            },
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 1
            },
            xaxis: {
                type: 'text',
                categories: category,
                labels: {
                    show: false,
                }
            },
            yaxis: {
                min: 0, max: 100
            },
            tooltip: {
                enabled: true,
                y: {
                    formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                        return Math.round(value * 100) / 100 + "%"
                    }
                }
            }
        };

        if (title) {
            options.title = {
                text: title,
                offsetX: 24,
                style: {
                    fontSize: '18px',
                    cssClass: 'apexcharts-yaxis-title'
                }
            };
        }

        let chart = new ApexCharts(element.nativeElement, options);
        chart.render();
    }
}

export default PortalKreonetWidgetAvailabilityComponent;