import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dizest.statuscard');
import { Input } from '@angular/core';

@Component({
    selector: 'wiz-portal-dizest-statuscard',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet-v4/branch/main/build/src/app/portal.dizest.statuscard/view.scss */
.info-box {
  background: var(--wc-blue-light);
  padding: 16px;
  font-size: 14px;
  margin: 12px 12px;
  border-radius: 14px;
  flex: auto;
}
.info-box table {
  margin-bottom: 8px;
  width: 100%;
}
.info-box table td {
  padding: 0;
  line-height: 1;
  white-space: nowrap;
}
.info-box table .title {
  font-weight: 800;
}
.info-box table .spare {
  border-bottom: 2px dotted rgba(116, 108, 112, 0.1);
}
.info-box.process-list {
  padding: 0;
  overflow: auto;
}`],
})
export class PortalDizestStatuscardComponent {
    @Input() title: string = '';
    @Input() items: any = [];

    constructor() {
    }

}

export default PortalDizestStatuscardComponent;