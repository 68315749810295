import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonetadm.board.list');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-kreonetadm-board-list',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet-v4/branch/main/build/src/app/portal.kreonetadm.board.list/view.scss */
.card-header h2 {
  font: normal normal bold 30px Montserrat;
}

span {
  font: normal normal normal 16px SUIT;
}

table {
  text-align: center;
}

.form-group {
  padding: 15px 0px;
  border-top: 1px solid #E3E3E3;
}
.form-group .form-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px SUIT;
  margin-bottom: 0px;
}
.form-group .form-selectgroup-label {
  padding: 7px 16px;
}

.form-selectgroup-input:checked + .form-selectgroup-label {
  z-index: 1;
  color: #FE5A33;
  background: rgba(254, 90, 51, 0.04);
  border-color: #FE5A33;
}

.edit-form {
  padding: 24px 32px;
}

.btn-season {
  background-color: #FE5A33;
  border: 1px solid #FE5A33;
  color: white;
}
.btn-season:hover {
  background-color: #fff;
  border: 1px solid #FE5A33;
  color: #FE5A33;
}

.page-icon.back-btn {
  cursor: pointer;
  background-color: #FE5A33 !important;
}

input,
textarea {
  text-align: left !important;
  border-radius: 0 !important;
}

table th, table td {
  white-space: nowrap;
  text-align: left;
}`],
})
export class PortalKreonetadmBoardListComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public categoryMap: any = { notice: '공지사항', report: '보고서', article: '보도자료' };
    public category: any = 'notice';
    public items: any = [];
    public loaded: boolean = false;

    public search: any = { page: 1 };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/authenticate');
        this.category = WizRoute.segment.category;
        this.search.category = this.category;
        await this.load();
    }

    public async ngDoCheck() {
        if (WizRoute.segment.category != this.category) {
            this.category = WizRoute.segment.category;
            this.search.category = this.category;
            await this.load();
        }
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        this.items = [];
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.items = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }
}

export default PortalKreonetadmBoardListComponent;