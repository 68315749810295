import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonet.graph.pie');
import { OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import ApexCharts from 'apexcharts';

@Component({
    selector: 'wiz-portal-kreonet-graph-pie',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet-v4/branch/main/build/src/app/portal.kreonet.graph.pie/view.scss */
/* src/app/portal.kreonet.graph.pie/view.scss: no such file or directory */`],
})
export class PortalKreonetGraphPieComponent implements OnInit {

    @Input() title: string = '';
    @Input() series: any = [];
    @Input() labels: any = [];
    @Input() height: number = 180;

    @ViewChild('chart')
    public element: ElementRef;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.render();
    }

    public async render() {
        let options = {
            series: this.series,
            labels: this.labels,
            chart: {
                height: this.height,
                type: 'donut',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '60%',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '12px',
                                offsetY: -5
                            },
                            value: {
                                offsetY: 5
                            },
                            total: {
                                show: true,
                                label: this.title ? this.title : 'Total'
                            }
                        }
                    }
                }
            },
            dataLabels: {
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold'
                },
                formatter: function (val, opts) {
                    return Math.round(val);
                }
            },
            grid: {
                padding: {
                    right: 0,
                    left: 0
                }
            },
            legend: {
                show: false,
                position: 'top'
            }
        };

        let chart = new ApexCharts(this.element.nativeElement, options);
        chart.render();
    }
}

export default PortalKreonetGraphPieComponent;