import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.apply.admin.notice.item');
import { OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";
import $ from "jquery";
import moment from "moment";

@Component({
    selector: 'wiz-portal-apply-admin-notice-item',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet-v4/branch/main/build/src/app/portal.apply.admin.notice.item/view.scss */
.page-icon.back-btn {
  cursor: pointer;
  background-color: #FE5A33 !important;
}

input {
  text-align: left !important;
  border-radius: 0 !important;
}

.editor-container {
  display: flex;
  height: 100%;
  position: relative;
}

.editor-area {
  flex: auto;
}

.editor-body {
  max-width: 980px;
  margin: 0 auto;
  height: 100%;
}

.info-area {
  height: 100%;
  overflow: auto;
  width: 360px;
  background-color: #fff;
  padding: 24px;
  padding-top: 18px;
  border-top: 3px solid #FE5A33;
  border-left: 1px solid var(--wc-border);
}
.info-area .form-group {
  margin-bottom: 24px;
}
.info-area .form-group:last-child {
  margin-bottom: 0;
}
.info-area label {
  margin: 0;
  display: flex;
  align-items: center;
}
.info-area .form-selectgroup .form-selectgroup-item {
  display: inline-block;
  margin-right: 4px;
}
.info-area img {
  width: 100%;
}`],
})
export class PortalApplyAdminNoticeItemComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @ViewChild('editor')
    public editorElement: ElementRef;

    @Input() post_id: any = "new";
    public category: any = 'notice';
    public item: any = null;
    public editor: any;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/authenticate');
        await this.init();
    }

    public async init() {
        await this.service.render();

        let element: any = this.editorElement.nativeElement;
        if ($(element).parent().find(".ck-content").length == 0) {
            let toolbar = 'heading | bold italic strikethrough underline | blockQuote code | bulletedList numberedList | outdent indent | uploadImage insertTable link codeBlock horizontalLine'.split(' ');

            this.editor = await ClassicEditor.create(element, {
                toolbar: {
                    items: toolbar,
                    shouldNotGroupWhenFull: true
                },
                removePlugins: ["MediaEmbedToolbar"],
                table: ClassicEditor.defaultConfig.table,
                simpleUpload: {
                    uploadUrl: '/file/upload'
                }
            });
        }

        if (this.post_id == 'new') {
            this.item = {
                category: 'notice',
                status: 'draft',
                title: '',
                content: '',
                featured_image: '',
                created: moment().format("YYYY-MM-DD")
            };
        } else {
            const { code, data } = await wiz.call("get", { id: this.post_id });
            this.item = data;
            this.item.created = moment(this.item.created).format("YYYY-MM-DD");
        }

        await this.service.render();

        this.editor.data.set(this.item.content);
    }

    public async removeImage() {
        this.item.featured_image = '';
        await this.service.render();
    }

    public async updateImage() {
        let img = await this.service.file.read({ type: 'image', accept: 'image/*', width: 512, quality: 1 });
        this.item.featured_image = img;
        await this.service.render();
    }

    public async update() {
        this.item.content = this.editor.data.get();
        let data: any = JSON.stringify(this.item);
        let res: any = await wiz.call("update", { data: data });
        if (!this.item.id) {
            this.service.href(`/admin/research/notice/${res.data}`);
        }
        await this.alert('저장되었습니다', 'Saved', 'success', '확인');
        if (!this.item.id) {
            location.reload();
        }
    }

    public async delete() {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        await wiz.call("delete", { id: this.item.id });
        this.service.href(`/admin/research/notice`);
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

}

export default PortalApplyAdminNoticeItemComponent;