import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.research');
import { OnInit, OnDestroy, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-research',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet-v4/branch/main/build/src/app/page.admin.research/view.scss */
/* src/app/page.admin.research/view.scss: no such file or directory */`],
})
export class PageAdminResearchComponent implements OnInit, OnDestroy, DoCheck {
    public PAGE: any;
    public ITEM: any;

    constructor(@Inject( Service) public service: Service) {
        if (!WizRoute.segment.page)
            return service.href("/admin/info/users");
        this.PAGE = WizRoute.segment.page;
        this.ITEM = WizRoute.segment.item;
    }

    public async ngDoCheck() {
        if (this.PAGE != WizRoute.segment.page) {
            this.PAGE = WizRoute.segment.page;
            await this.service.render();
        }

        if (this.ITEM != WizRoute.segment.item) {
            this.ITEM = WizRoute.segment.item;
            await this.service.render();
        }
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        await this.service.render();
    }

}

export default PageAdminResearchComponent;