import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.notice.item');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import showdown from 'showdown';
import { Location } from '@angular/common'

@Component({
    selector: 'wiz-page-notice-item',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet-v4/branch/main/build/src/app/page.notice.item/view.scss */
.wiz-view {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  padding-top: 24px;
}

.wiz-view::-webkit-scrollbar {
  display: none;
}

.season-page {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  max-width: 1280px;
  width: 90vw;
  margin: 0px auto;
  border-top: 1px solid #2b2c2e;
  border-left: 1px solid #2b2c2e;
  border-right: 1px solid #2b2c2e;
}
@media (max-width: 768px) {
  .season-page {
    width: 92vw;
  }
}

.season-page::-webkit-scrollbar {
  display: none;
}

.header {
  background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.5), transparent), url("/assets/background/board.jpg");
  min-height: 320px;
  max-height: 320px;
  padding-bottom: 12px;
}
.header .text-container {
  max-width: 1120px;
}
.header .xmark {
  position: absolute;
  top: 48px;
  right: 48px;
  cursor: pointer;
}
.header .xmark i {
  color: #fff;
  font-size: 32px;
}
.header .xmark:hover i {
  color: #FF5933;
}
@media (max-width: 768px) {
  .header .xmark {
    top: 32px;
    right: 32px;
  }
}
.header .title {
  font-size: 36px;
}

.content {
  padding-top: 102px;
  padding-bottom: 176px;
  min-height: 100%;
}

.content-container {
  padding: 64px auto;
  font-size: 18px;
}
.content-container .content-body {
  max-width: 860px;
  margin: 0 auto;
}`],
})
export class PageNoticeItemComponent implements OnInit {
    constructor(@Inject( Service) public service: Service,@Inject( Location)  public location: Location) { }

    public post_id: any;
    public item: any;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/');
        this.post_id = WizRoute.segment.id;
        await this.load();
    }

    public async load() {
        const { code, data } = await wiz.call("get", { id: this.post_id });
        this.item = data;
        await this.service.render();
    }

    public desc() {
        let item: any = this.item;
        let text: any = item.content;
        let converter = new showdown.Converter();
        text = converter.makeHtml(text);
        return text;
    }

    public back() {
        this.location.back();
    }
}

export default PageNoticeItemComponent;