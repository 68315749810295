import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonetadm.users');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-kreonetadm-users',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet-v4/branch/main/build/src/app/portal.kreonetadm.users/view.scss */
.container {
  height: max-content;
}

.card-table .table {
  position: absolute;
}
.card-table .table th {
  font-size: 14px;
  background: #EFF4FE;
  padding: 8px 12px;
}
.card-table .table thead {
  position: sticky;
  top: 0;
}
.card-table .table tbody {
  background: #fff;
}
.card-table .table tbody tr {
  cursor: pointer;
}
.card-table .table tbody tr:hover {
  background: var(--wc-background);
}
.card-table .table tbody td {
  white-space: nowrap;
}
.card-table .table tbody .user-status-icon {
  display: inline-block;
  width: 20px;
  text-align: center;
  line-height: 1;
  padding: 4px 0;
  font-size: 12px;
  border-radius: 10px;
  background: var(--wc-blue);
  color: #fff;
}
.card-table .table tbody .btn i {
  width: 16px;
}`],
})
export class PortalKreonetadmUsersComponent implements OnInit {

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        await this.load();
    }

    public listloaded: boolean = false;
    public list: any = [];
    public status: string = 'active';

    public search: any = {
        page: 1,
        text: ''
    };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public async alert(message: string, status: any = "error") {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    public async load(page: number = 1, status: any = null) {
        if (!status) status = this.status;
        this.listloaded = false;
        let { code, data } = await wiz.call("list", { page: page, text: this.search.text, status: status });
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.search.page = page;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.listloaded = true;
        this.status = status;
        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }

    public async changeStatus(item: any, status: string) {
        let res = false;
        if (status == 'active')
            res = await this.alert('사용자 접속을 승인하겠습니까?', 'success');
        if (status == 'inactive')
            res = await this.alert('사용자를 차단하겠습니까?', 'error');
        if (!res) return;
        item.status = status;
        await wiz.call("changeStatus", item);
        await this.load(this.search.page);
    }

    public async changeRole(item: any) {
        let res = false;
        if (item.role == 'admin') {
            res = await this.alert('관리자 권한을 해제하시겠습니까??', 'error');
        } else {
            res = await this.alert('관리자로 지정하시겠습니까?', 'success');
        }
        if (!res) return;
        item.role = item.role == 'admin' ? 'user' : 'admin';
        await wiz.call("changeRole", item);
        await this.load(this.search.page);
    }

}

export default PortalKreonetadmUsersComponent;