import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonet.widget.weathermap');
import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import toastr from "toastr";

@Component({
    selector: 'wiz-portal-kreonet-widget-weathermap',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet-v4/branch/main/build/src/app/portal.kreonet.widget.weathermap/view.scss */
@-webkit-keyframes line-move {
  from {
    stroke-dashoffset: 0px;
    transform: translate(0px);
  }
  to {
    stroke-dashoffset: -96px;
    transform: translate(0px);
  }
}
@keyframes line-move {
  from {
    stroke-dashoffset: 0px;
    transform: translate(0px);
  }
  to {
    stroke-dashoffset: -96px;
    transform: translate(0px);
  }
}
@-webkit-keyframes line-move-reverse {
  from {
    stroke-dashoffset: -96px;
    transform: translate(0px);
  }
  to {
    stroke-dashoffset: 0px;
    transform: translate(0px);
  }
}
@keyframes line-move-reverse {
  from {
    stroke-dashoffset: -96px;
    transform: translate(0px);
  }
  to {
    stroke-dashoffset: 0px;
    transform: translate(0px);
  }
}
.weather-map-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.weather-map-container .actions {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2000;
}

.weather-map {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 580px;
}
.weather-map img {
  width: 100%;
}
.weather-map .map-location {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.weather-map .map-location .map-location-drop {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
}
.weather-map .map-location .line {
  z-index: 999;
  position: absolute;
  line-height: 1;
}
.weather-map .map-location .line svg {
  position: relative;
  top: 0;
  left: 0;
  stroke-dasharray: 80px 16px;
  fill: transparent;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.weather-map .map-location .line svg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 11px;
  height: 11px;
  border-radius: 30px;
}
.weather-map .map-location .line svg path {
  stroke-width: 4px;
}
.weather-map .map-location .line .line-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.weather-map .map-location .line .line-animation:first-child svg {
  -webkit-animation-name: line-move;
  animation-name: line-move;
}
.weather-map .map-location .line .line-animation:last-child svg {
  -webkit-animation-name: line-move-reverse;
  animation-name: line-move-reverse;
}
.weather-map .map-location .location {
  z-index: 1011;
  width: 4.5%;
  position: absolute;
  line-height: 1;
}
.weather-map .map-location .location .location-handle {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid var(--wc-blue);
  border-radius: 100%;
  line-height: 0;
  background: #fff;
  cursor: pointer;
}
.weather-map .map-location .location .location-name {
  display: none;
  white-space: nowrap;
  padding: 4px 12px;
  border-radius: 11px;
  text-align: center;
  position: absolute;
  top: -120%;
  left: -44%;
  background: #303443;
  color: #fff;
  line-height: 1;
}
.weather-map .map-location .location .location-name::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -10%);
  width: 0;
  height: 0;
  border-top: 7px solid #303443;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.weather-map .map-location .location:hover .location-name {
  display: block;
}
.weather-map .map-location .location.active .location-name {
  display: none !important;
}
.weather-map .map-location .location.selected .location-handle {
  background: var(--wc-background-dark);
}
.weather-map .map-location .location.selected .location-name {
  display: block;
}

.line-label {
  width: 160px;
  max-width: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
}
.line-label .colors {
  font-size: 14px;
}
.line-label .colors .color-container {
  margin-bottom: 4px;
}
.line-label .colors .color {
  width: 16px;
  height: 16px;
  border-radius: 4px;
}
.line-label .colors input {
  width: 80px;
}`],
})
export class PortalKreonetWidgetWeathermapComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
        await this.load();

        this.event.bind = {};
        this.event.bind.reload = this.load;
        this.event.bind.refresh = this.loadTraffic;
        this.event.update = {};
        this.event.update.map = this.update;
        this.event.update.color = this.updateColor;

        this.event.data = this.data;
        await this.service.render();
    }

    @Input() mode: string = 'view'; // view or edit
    @Input() event: any = {}; // event binding

    @ViewChild('map')
    public map: ElementRef;

    public dragitem: any = null;
    public selected: any = null;
    public startOffset: any = null;
    public data: any = { location: [], line: [], traffic: {}, color: [] };
    public cache: any = { location: {} };

    public async load() {
        await this.loadLocation();
        await this.loadLine();
        await this.loadColor();
        await this.loadTraffic();
        if (!this.selected) await this.select('daejeon');
        await this.service.render();
    }

    public async loadColor() {
        let { code, data } = await wiz.call("colors");
        if (code != 200) return false;
        for (let i = 0; i < data.length; i++) {
            if (data[i + 1]) {
                data[i].title = data[i].id + " - " + data[i + 1].id
            } else {
                data[i].title = data[i].id + " + Mbps "
            }
        }
        this.data.color = data;
        return data;
    }

    public async loadLocation() {
        let { code, data } = await wiz.call("location");
        if (code != 200) return false;
        this.data.location = data;

        let cache = {};
        for (let i = 0; i < data.length; i++)
            cache[data[i].id] = data[i];
        this.cache.location = cache;
        return data;
    }

    public async loadLine() {
        let { code, data } = await wiz.call("line");
        if (code != 200) return false;

        let loc = this.cache.location;
        let cache = {};
        for (let i = 0; i < data.length; i++) {
            let { location_from, location_to, network_id } = data[i];
            location_from = loc[location_from];
            location_to = loc[location_to];
            if (!location_from || !location_to) continue;

            let key = location_from.id + "-" + location_to.id;
            let reversekey = location_to.id + "-" + location_from.id;

            let top1 = location_from.top;
            let top2 = location_to.top;
            let left1 = location_from.left;
            let left2 = location_to.left;

            if (!cache[key] && !cache[reversekey]) {
                let top = top1 > top2 ? top2 : top1;
                let left = left1 > left2 ? left2 : left1;
                let height = Math.abs(top1 - top2);
                let width = Math.abs(left1 - left2);
                let up = location_from.id + "-" + location_to.id;
                let down = location_to.id + "-" + location_from.id;

                let mode = 1;
                if (left1 > left2 && top1 < top2) mode = 2;
                if (left1 < left2 && top1 > top2) mode = 2;

                if (left1 == left2 && top1 < top2) {
                    up = location_to.id + "-" + location_from.id;
                    down = location_from.id + "-" + location_to.id;
                } else if (left1 > left2) {
                    up = location_to.id + "-" + location_from.id;
                    down = location_from.id + "-" + location_to.id;
                }

                let { offsetWidth, offsetHeight } = this.map.nativeElement;

                left = left + 2.25;
                top = top + 2.25 * offsetWidth / offsetHeight;

                let weight1 = {};
                let weight2 = {};

                let weight = 4;
                if (height < width) {
                    weight1.top = -weight + "px";
                    weight2.top = weight + "px";
                } else {
                    weight1.left = -weight + "px";
                    weight2.left = weight + "px";
                }

                cache[key] = { top, left, width, height, up, down, mode, weight1, weight2 };
            }

            let target = key;
            if (cache[reversekey]) target = reversekey;

            cache[target][key] = network_id;
        }
        this.data.line = [];
        for (let key in cache) {
            this.data.line.push(cache[key])
        }
        return data;
    }

    public async loadTraffic() {
        let { code, data } = await wiz.call("line");

        this.data.traffic = {};

        let colormap = this.data.color;
        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            let traffic = item.traffic;
            let _from = item.location_from;
            let _to = item.location_to;
            let key = _from + "-" + _to;

            if (!traffic) traffic = 0;
            let color = null;
            for (let j = 0; j < colormap.length; j++) {
                if (colormap[j].id > traffic)
                    break;
                color = colormap[j].color
            }

            if (color)
                this.data.traffic[key] = color;
        }

        await this.service.render();
    }

    public getLineStyle(line: string) {
        let style = { stroke: '#A9A9A9' };
        if (this.data.traffic[line])
            style.stroke = this.data.traffic[line];
        return style;
    }

    public onDragOver(event) {
        event.preventDefault();
    }

    public async onDrop(event) {
        event.preventDefault();
        if (this.mode == 'view') return;
        let { sOffsetX, sOffsetY } = this.startOffset;
        let { offsetX, offsetY } = event;
        let { offsetWidth, offsetHeight } = this.map.nativeElement;
        this.dragitem.left = (offsetX - sOffsetX) / offsetWidth * 100 - 0.5;
        this.dragitem.top = (offsetY - sOffsetY) / offsetHeight * 100 - 0.5;
        this.dragitem = null;
        this.startOffset = null;

        await this.loadLine();
        await this.service.render();
    }

    public async ngDrag(event, location) {
        if (this.mode == 'view') return event.preventDefault();
        let { offsetX, offsetY } = event;
        this.startOffset = { sOffsetX: offsetX, sOffsetY: offsetY };
        this.dragitem = location;
        await this.service.render();
    }

    public async select(location: string) {
        this.selected = this.cache.location[location];
        if (this.event.selected) await this.event.selected(this.selected);
        await this.service.render();
    }

    public async update(data) {
        let { code } = await wiz.call('update', { data: JSON.stringify(data) });
        await this.updateColor(this.data.color);
        if (code == 200) toastr.success("저장되었습니다");
        await this.load();
    }

    public async updateColor(data) {
        await wiz.call('updateColor', { data: JSON.stringify(data) });
    }
}

export default PortalKreonetWidgetWeathermapComponent;